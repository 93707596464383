<template>
  <div class="inline-block">
    <el-button v-if="!isEdit" :loading="loading" type="info" size="mini" class="filter-item" @click="to">
      添加图片分组
    </el-button>
    <color-text-btn v-else :loading="loading" @click="to">编辑</color-text-btn>

    <DistributionDialog
      type="picture"
      v-model="value"
      v-if="showDistributionDialog"
      :visible.sync="dialogVisible"
      :form="form"
      :isEdit="isEdit"
      :resetMergeData="resetMergeData"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import DistributionDialog from '@/views/components/distributionDialog'
import { commonEheaderMixin } from '@/mixins'
import { getPictureGroupDetail } from '@/api/image/pictureGroupApi'
import { checkPermission } from '@/utils'
import { PUBLIC_PIC_ENABLE } from '@/utils/constant'

export default {
  mixins: [commonEheaderMixin],
  components: { DistributionDialog },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    isEdit: Boolean
  },

  data() {
    return {
      loading: false,
      form: {},
      value: [],
      resetMergeData: {
        disable: PUBLIC_PIC_ENABLE
      },
      showDistributionDialog: false
    }
  },

  watch: {
    dialogVisible(newVal) {
      if (newVal) return
      this.$nextTick(() => {
        this.value = []
        this.showDistributionDialog = false
      })
    }
  },

  methods: {
    async to() {
      this.form = {}
      this.showDistributionDialog = true
      if (this.isEdit && checkPermission(['externaladmin:publicGalleryGroup:editGroup:detail'])) {
        const [err, data] = await this.getPictureGroupDetail()
        if (err) return
        this.value = data.imageIdList || []
        this.$set(this.form, 'name', data.name)
        this.$set(this.form, 'id', data.id)
      }
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },

    async getPictureGroupDetail() {
      const { id } = this.data
      this.loading = true
      try {
        const { code, detail } = await getPictureGroupDetail({ id })
        return [!$SUC({ code }), detail || {}]
      } catch {
        return [true, {}]
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
